import React from "react";



const Page404 = () =>{

    return (
        <p>Pagina nao encontrada</p>
    )
}

export default Page404