import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from "@mui/x-date-pickers/TimePicker";

import { TextField } from '@mui/material';
import { pt } from "date-fns/locale";

export default function TimePickerField(props) {

    const { name, label, value, dateFormat, onChange, ...other } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider locale={pt} dateAdapter={AdapterDateFns}>
        <TimePicker
            ampm={false}
            label={label}
            name={name}
            value={value}
            autoOk={true}
            {...other}
            onChange={date => onChange(convertToDefEventPara(name, date))}
            renderInput={(params) => <TextField {...params } />}
        />
    </LocalizationProvider>
    )
}
