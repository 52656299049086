import { Avatar, Box, Button, Card, Checkbox, Chip, Collapse, FormControlLabel, FormGroup, Grid, IconButton, Paper, TextField, Typography, styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import corporateService from "app/services/corporate";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import FilterListIcon from "@mui/icons-material/FilterList";
import ButtonMenu from "@jumbo/components/ButtonMenu/ButtonMenu";
import { CheckCircle, LocationOn } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppointmentScheduleModal from "./AppointmentScheduleModal";
import BeneficiaryDetailModal from "./BeneficiaryDetailModal";
import BeneficiaryEditModal from "./BeneficiaryEditModal";
import ContentLoader from "@jumbo/components/ContentLoader/ContentLoader";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import Swal from "sweetalert2";
import CheckIcon from '@mui/icons-material/Check';
import withReactContent from "sweetalert2-react-content";
import {
  useQuery,
} from '@tanstack/react-query';
import { getContrastColor } from "@jumbo/utils";
import { pink } from "@mui/material/colors";
const MySwal = withReactContent(Swal);



const WhiteTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

function getPreApprovalColor(value) {

  if (value == 0) {
    return '#ffc107'
  } else if (value > 0) {
    return '#42ba96'
  } else {
    return '#d9e2ef'
  }
}

/* function getUsedColor(preApproval,value){
  if()
  else{
    return '#d9e2ef'
  }
} */

const BeneficiaryPreApprovalPage = () => {
/*   const [data, setData] = useState();
 */  const [loading, setLoading] = useState(true)
  const [currentBeneficiary, setCurrentBeneficiary] = useState(null);
  const [actDialog, setActDialog] = useState(null);
  const [beneficiaryEditDialog, setBeneficiaryEditDialog] = useState(null);
  const [beneficiaryDetailDialog, setBeneficiaryDetailDialog] = useState(null);
  const [columns, setColumns] = useState([]);
  const [name, setName] = useState('');
  const [pt, setPt] = useState(0);
  const [isEmployee, setIsEmployee] = useState(0);
  const [expandFilters, setExpandFilters] = useState(true);

  /*   const getData = () => {
      corporateService.beneficiariesPreAprovalls().then((data) => {
        console.log(data);
        setData(data);
        setLoading(false)
      })
    }
    React.useEffect(() => {
      getData()
    }, []) */

  const { data, isError, isFetching, isLoading, refetch } =
    useQuery(
      ['acts-usage',
        pt,
        name,
        isEmployee,
      ],
      async () => {
        const axiosData = await corporateService.beneficiariesPreAprovalls(name,isEmployee,pt)

        const columnsAux = [];

        columnsAux.push({
          id: 'employee', //id used to define `group` column
          header: '',
          muiTableHeadCellProps: {
            align: 'center'
          }
          , muiTableBodyCellProps: {
            align: 'center'
          },
          columns: [
            {
              id: 'name',
              accessorKey: 'name', //access nested data with dot notation
              header: 'NOME',
              muiTableHeadCellProps: {
                align: 'left'
              }
              , muiTableBodyCellProps: {
                align: 'left'
              },

            },
            {
              id: 'pt_network',
              accessorKey: 'pt_network', //access nested data with dot notation
              header: 'Rede PT',
              size: 30,
              muiTableHeadCellProps: {
                align: 'left'
              }
              , muiTableBodyCellProps: {
                align: 'left'
              },
              Cell: ({ row }) => (
                <Box>
                  {row.original.pt_network ? <CheckIcon sx={{ color: '#4BB543' }} /> : null}
                </Box>
              ),

            },
          ]
        });

        columnsAux.push({
          id: 'dfm', //id used to define `group` column
          header: 'ACTOS INCLUIDOS',
          muiTableHeadCellProps: {
            align: 'center'
          }
          , muiTableBodyCellProps: {
            align: 'center'
          },
          columns: [

          ]

        })
        axiosData.columns.map((item, index) => {
          columnsAux[1].columns.push({
            id: "v" + item.voucher_id,
            header: item.name,
            muiTableHeadCellProps: {
              align: 'center'
            }
            , muiTableBodyCellProps: {
              align: 'center'
            },
            columns: [
              {
                id: item.field1,
                accessorKey: item.field1,
                header: "PRÉ-APROVADO",
                Cell: ({ row }) => (
                  <Box>
                    {Number(row.original[item.field1]) ?
                      <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: row.original[item.field1] == 0 ? 'white' : 'white', backgroundColor: row.original[item.field1] == 0 ? '#ffc107' : '#42ba96' }} label={row.original[item.field1]} />
                      :
                      <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: 'black', backgroundColor: '#d9e2ef' }} label={row.original[item.field1]} />
                    }
                  </Box>
                ),
                muiTableHeadCellProps: {
                  align: 'center'
                }
                , muiTableBodyCellProps: {
                  align: 'center'
                },
              },
              {
                id: item.field2,
                accessorKey: item.field2,
                header: "UTILIZADOS",
                muiTableHeadCellProps: {
                  align: 'center'
                }
                , muiTableBodyCellProps: {
                  align: 'center'
                },
                Cell: ({ row }) => (
                  <Box>
                    {Number(row.original[item.field2]) ?
                      <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: row.original[item.field2] > row.original[item.field1] ? 'white' : 'white', backgroundColor: row.original[item.field2] > row.original[item.field1] ? '#df4759' : '#20c997' }} label={row.original[item.field2]} />
                      :
                      <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: 'black', backgroundColor: '#d9e2ef' }} label={row.original[item.field2]} />
                    }
                  </Box>
                ),
              }
            ]
          },)
        })

        setColumns(columnsAux)

        const beneficiariesParent = axiosData.beneficiaries.filter(item => item.is_parent == 1);
        const childrens = beneficiariesParent.map((it) => {
          it.subRows = axiosData.beneficiaries.filter(itm => itm.order_base == it.order_base && itm.is_parent == 0).map((i) => { i.subRows = []; return i; })
          return it;
        })
        console.log(childrens)
        return childrens
      },
      {},
    );

  const handleCloseActDialog = () => {
    setActDialog(null);
  }

  const handleCloseBeneficiaryEditDialog = () => {
    setBeneficiaryEditDialog(null);
  }
  const handleCloseBeneficiaryDetailDialog = () => {
    setBeneficiaryDetailDialog(null);
  }

  function removeBeneficiary(row) {
    if (row.subRows != undefined && row.subRows.length > 0) {
      MySwal.fire({
        title: "Eliminação de Beneficiário",
        text: "Benificiário com agregado,deseja eliminar mesmo assim?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          MySwal.fire({
            title: 'Eliminação',
            text: 'Eliminação de Beneficiário com sucesso!'
          })
        }
      })
    } else {
      MySwal.fire({
        title: 'Eliminação',
        text: 'Eliminação de Beneficiário com sucesso!'
      })
    }

  }

  return (
    <Box>
      <Typography sx={{ color: '#5d5d5d' }} fontWeight={900} variant="h1">Pré-Aprovações</Typography>
      <Card>
        <MaterialReactTable
          columns={columns}
          initialState={{ showColumnFilters: false, density: 'compact', }}
          data={data ?? []}
          enableExpanding
          enableExpandAll
          enableColumnFilters={false}
          enableColumnOrdering={false}
          enableColumnFilterMode={false}
          enableHiding={false}
          paginateExpandedRows={true}
          enableFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          muiTablePaginationProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiBottomToolbarProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiTopToolbarProps={{ sx: { backgroundColor: '#bf2f38' } }}
          muiTableHeadRowProps={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            sx: {
              background: '#6c757d',
              "& .MuiTableCell-head": {
                color: "white",
                padding: 1,
              },
            }
          }}
          enableTableHead={true}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
          localization={MRT_Localization_PT_BR}
          muiTableBodyProps={{
            sx: (theme) => ({
              '& tr:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
              },
              '& tr:nth-of-type(even)': {
                backgroundColor: '#FFF',
              },
            }),
          }}
          enableColumnActions={false}

          /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <IconButton onClick={() => { setExpandFilters((prev) => !prev) }}>
                      <FilterListIcon sx={{ fontSize: 20, color: "white" }} />
                    </IconButton>
                    <Typography sx={{ color: "#FFF" }}>Filtrar por</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <WhiteTextField
                          label="Nome"
                          fullWidth
                          size="small"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <FormGroup>
                          <FormControlLabel 
                          label={<span style={{color:'white'}}>Rede Pt</span>}
                          control={<Checkbox
                          checked={pt}
                          onChange={(e) => { setPt(e.target.checked) }}
                          inputProps={{ 'aria-label': 'controlled' }}
                          sx={{
                            color: '#FFFFFF',
                            '&.Mui-checked': {
                              color: '#808080',
                            },
                          }}
                        />}  />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={1.5}>
                        <FormGroup>
                          <FormControlLabel 
                            label={<span style={{color:'white'}}>Colaborador</span>}
                            control={<Checkbox
                            checked={isEmployee}
                            onChange={(e) => { setIsEmployee(e.target.checked?1:0) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              color: '#FFFFFF',
                              '&.Mui-checked': {
                                color: '#808080',
                              },
                            }}
                          />}  />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            );
          }}

        />
      </Card>
      {actDialog ? <AppointmentScheduleModal open={actDialog} handleClose={handleCloseActDialog} beneficiary={currentBeneficiary} /> : null}
      {beneficiaryEditDialog ? <BeneficiaryEditModal open={beneficiaryEditDialog} handleClose={handleCloseBeneficiaryEditDialog} beneficiary={currentBeneficiary} /> : null}
      {beneficiaryDetailDialog ? <BeneficiaryDetailModal open={beneficiaryDetailDialog} handleClose={handleCloseBeneficiaryDetailDialog} beneficiary={currentBeneficiary} /> : null}
    </Box>
  );
}

export default BeneficiaryPreApprovalPage;