import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { TextField } from '@mui/material';
import { pt } from "date-fns/locale";
import { format, parseISO } from 'date-fns';
import WhiteTextField from '../WhiteTextField/WhiteTextField';

const styles = {
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiIconButton-root':{
      color:'white'
  }
};

export default function DatePickerField(props) {

    const { name, label, value,styled, dateFormat, onChange, ...other } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider locale={pt} dateAdapter={AdapterDateFns}>
        <DatePicker
            label={label}
            name={name}
            format='dd/MM/yyyy'
            value={new Date(value) || null}
            onChange={date => onChange(convertToDefEventPara(name, new Date(date).toLocaleDateString('en-GB')))}
            sx={styled?styles:null}
            renderInput={(params) => <WhiteTextField size='small' {...params } fullWidth />}
        />
    </LocalizationProvider>
    )
}
