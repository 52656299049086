import React, { useEffect, useState,createContext } from 'react';
import ContentLoader from "@jumbo/components/ContentLoader";
import corporateService from '../services/corporate';
import { useNavigate } from 'react-router-dom';


export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getMe();
    }, []);

    const getMe=()=>{
        corporateService.me().then((data) => {
            setUser(data);
            setIsAuthenticated(true);
            setIsLoaded(true);
        }).catch((error) => {
            if(error.response){
                localStorage.removeItem('token');
                navigate('/login')
                setIsLoaded(true);
            }else{
                navigate('/')
                setIsLoaded(true);
            }

        });
    }


    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        setIsAuthenticated(false);
        navigate('/login')
    }

    const signin = (username, password) => {
        return new Promise((resolve, reject) => {
            corporateService.login(username, password)
                .then((data) => {
                    resolve(data)
                    localStorage.setItem('token', data.token);
                    getMe();
                    navigate("/",{replace:true});

                })
                .catch((error) => reject(error))
        })
    };

    return (
        <React.Fragment>
            {!isLoaded ? <ContentLoader open={true} /> :
                <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated, logout, signin }}>
                    {children}
                </AuthContext.Provider>}
        </React.Fragment>
    )
};