import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Typography, TextField, Grid, MenuItem, Box } from "@mui/material";
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import corporateService from "app/services/corporate";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Dropzone from "react-dropzone";

const MySwal = withReactContent(Swal);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    notes: yup.string().required('Obrigatório').min(4, 'Deve ter 4 caracters no minimo'),
    subject_id: yup.string().required('Obrigatório'),


});


const NewTicketModal = ({ open, handleClose, loadData }) => {
    const [subjects, setSubjects] = useState([]);
    const [initialValues, setInitialValues] = useState({
        notes: '',
        subject_id: '',
        attachments:[]
    })

    const getSubjects = () => {
        corporateService.getTicketsSubjects().then(dt => setSubjects(dt))
    }
    useEffect(() => {
        getSubjects()
    }, []);
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth={'md'}
        >
            <Formik
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(data, { setSubmitting }) => {
                      const formData = new FormData();
                      data.attachments.map(item => {
                        formData.append('files[]', item);
                      }) 
                      data.attachments =null;
                      formData.append('data',JSON.stringify(data))
                      corporateService.createTicket(formData).then((dt) => {
                        loadData();
                        MySwal.fire({
                            icon: 'success',
                            title: 'Succeso',
                            text: 'Pedido criado'
                        })
                        handleClose()
                    })
                }}
            >
                {({ isSubmitting, values, setFieldValue, touched, errors, handleChange }) => (
                    <Form style={{ textAlign: 'left' }} noValidate autoComplete='off' id="ticket-form">
                        <DialogTitle>Novo Pedido </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Assunto</Typography>
                                    <TextField
                                        select
                                        name="subject_id"
                                        defaultValue={''}
                                        value={values.subject_id}
                                        onChange={handleChange}
                                        fullWidth
                                        error={touched.subject_id && Boolean(errors.subject_id)}
                                        helperText={touched.subject_id && errors.subject_id}
                                    >
                                        {subjects.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Notas do Pedido</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={values.notes}
                                        onChange={(e) => { setFieldValue('notes', e.target.value) }}
                                        minRows={6}
                                        maxRows={10}
                                        error={touched.notes && Boolean(errors.notes)}
                                        helperText={touched.notes && errors.notes}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Anexos</Typography>
                                    <Box
                                        gridColumn="span 4"
                                        border={'1px solid #A3A3A3'}
                                        borderRadius="5px"
                                        p="1rem"
                                    >
                                        <Dropzone
                                           accept={ ['image/*', '.doc', '.docx', '.pdf']}
                                            multiple={true}
                                            maxFiles={5}
                                            onDrop={(acceptedFiles) =>
                                                setFieldValue("attachments", acceptedFiles)
                                            }
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <Box
                                                    {...getRootProps()}
                                                    border={`2px dashed #bf2f38`}
                                                    p="1rem"
                                                    sx={{ "&:hover": { cursor: "pointer" } }}
                                                >
                                                    <input {...getInputProps()} accept=""/>
                                                    {!values.attachments ? (
                                                        <p>Adicione um anexo</p>
                                                    ) : (
                                                        <Box sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}>
                                                            <Box>
                                                                {values.attachments?.map((it, idx) => (
                                                                    <Typography key={idx}>{it.path}</Typography>

                                                                ))}
                                                            </Box>
                                                            <EditOutlinedIcon />
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Dropzone>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancelar</Button>
                            <Button type="submit" form="ticket-form">Gravar</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )

}

export default NewTicketModal;