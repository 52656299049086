import React, { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { Box, Card, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TodayIcon from '@mui/icons-material/Today';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LinearProgress } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import { styled } from '@mui/material/styles';
import CollapseChildren from "./CollapseChildren";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import { createCookie } from "app/utils/appHelpers";

const CollapseParent = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const CustomTypography = styled(Typography)(({ theme }) => ({
        color: '#5A5A5A',
        margin: 0
    }));

    const StyledTypography = ({ children }) => {
        return (
            <CustomTypography >
                {children}
            </CustomTypography>
        );
    };
    return (
        <Card sx={{ padding: 2, mb: 2 }}>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Typography color={'#5d5d5d'} sx={{ margin: 0,minWidth:30 }} fontWeight={500} variant="h5">
                        {data.name}
                    </Typography>
                </Box>

                <Box display={'flex'} gap={10} >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} minWidth={100} maxWidth={100} >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                            <Typography color={'#5d5d5d'} sx={{ marginBottom: 0,fontSize:'2rem'}} fontWeight={900} variant="h1">{data.subjects}</Typography>
                            <Box display={'flex'} flexDirection={'column'}>
                                <Typography variant="caption" sx={{ margin: 0, lineHeight: 1 }}>{Number(data.subjects)>1?'Consultas/Exames':'Consulta/Exame'}</Typography>
                                <Typography variant="caption" sx={{ margin: 0, lineHeight: 1 }}>{Number(data.subjects)>1?'Incluidos':'Incluido'}</Typography>
                            </Box>

                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}  minWidth={100} maxWidth={100}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                            <Typography color={'#5d5d5d'} sx={{ margin: 0,fontSize:'2rem'}} fontWeight={900} variant="h1">{data.locations}</Typography>
                            <Typography coponent={'div'} variant="caption" sx={{ margin: 0, lineHeight: 1 }}>{Number(data.locations)>1?'Locais de':'Local de'} <br/> {Number(data.locations)>1?'Prestações':'Prestação'}</Typography>

                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}  minWidth={100} maxWidth={100}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                            <Typography color={'#5d5d5d'} sx={{ margin: 0,fontSize:'2rem'}} fontWeight={900} variant="h1">{data.total_acts}</Typography>
                            <Typography coponent={'div'} variant="caption" sx={{ margin: 0, lineHeight: 1 }}>Total de<br/> Consultas</Typography>

                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}  minWidth={100} maxWidth={100}>
                        {data.percentage > 80 ? <ReportIcon color="error" /> : <Box height={4} width={4} />}
                        <Tooltip title={data.percentage + '%'}>
                            <LinearProgress sx={{ minWidth: 150, height: 8 }} color="primary" variant="determinate" value={data.percentage} />
                        </Tooltip>
                    </Box>
                    <Box  minWidth={100} maxWidth={300} display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
                        <Typography variant="caption">Última Utilização</Typography>
                        <Typography variant="caption" fontWeight={900}>{data.last_appointment}</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        id="fade-button"
                        aria-controls={openMenu ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={()=>{
                          createCookie('act-network-id',data.id,5)
                          navigate('/acts-usages')
                        }}><LocationOnIcon /><Box ml={2} />Ver Utilizações</MenuItem>
                        <MenuItem onClick={()=>{
                            navigate('/locations?network='+data.id)
                            
                        }}><TodayIcon /><Box ml={2} />Ver Locais de Prestação</MenuItem>
                        <MenuItem onClick={()=>{
                            navigate('/tickets');
                        }}><AddCircleIcon /><Box ml={2} />Solicitar Reforço</MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <CollapseChildren items={data.network} newtorkId={data.id}/>
            </Collapse>
        </Card>

    )

}

export default CollapseParent;