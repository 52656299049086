import { Box } from '@mui/material';
import React from 'react';
import {useDropzone} from 'react-dropzone';

function JumboDropZone(props) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    disabled: false
  });

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <Box sx={{width:'100%'}} bgcolor={'#F6F6F6'}>
      <div {...getRootProps({className: 'dropzone disabled'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
    </Box>
  );
}

export default JumboDropZone;