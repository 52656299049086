import React from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import TaskIcon from '@mui/icons-material/Task';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
const menus = [
    {
        uri: "/",
        label: 'Dashboard',
        type: "nav-item",
        icon: <DashboardIcon sx={{fontSize: 20}}/>
    },
    {
        uri: "/acts",
        label: 'Atos Contratados',
        type: "nav-item",
        icon: <VaccinesIcon sx={{fontSize: 20}}/>
    },
    {
        uri: "/beneficiaries-pre-approvals",
        label: 'Pré-Aprovações',
        type: "nav-item",
        icon: <AssignmentTurnedInIcon sx={{fontSize: 20}}/>
    },
    {
        uri: "/beneficiaries",
        label: 'Beneficiários',
        type: "nav-item",
        icon: <GroupsIcon sx={{fontSize: 20}}/>
    },
    {
        uri: "/acts-usages",
        label: 'Utilizações de Atos',
        type: "nav-item",
        icon: <DataUsageIcon sx={{fontSize: 20}}/>
    },
    {
        uri: "/locations",
        label: 'Locais de Prestação',
        type: "nav-item",
        icon: <LocalHospitalIcon sx={{fontSize: 20}}/>
    },
    {
        uri: "/tickets",
        label: 'Meus Pedidos',
        type: "nav-item",
        icon: <TaskIcon sx={{fontSize: 20}}/>
    }
];

export default menus;
