import React from "react";
import Home from "../pages/home";
import DemoPage from "../pages/home/DemoPage";
import UsersPage from "../pages/home/UsersPage";
import Login1 from "../pages/login1";

import Page from "@jumbo/shared/Page";
import Page404 from "app/pages/ErrorPapes/Page404";
import ActsPage from "app/pages/ActsPAge";
import BeneficiaryPage from "app/pages/BeneficiaryPage";
import ActsUsagePage from "app/pages/ActsUsage";
import LocationPage from "app/pages/LocationsPage";
import DashBoardPage from "app/pages/DashboardPage";
import TicketsPage from "app/pages/Tickets";
import PublicRoute from "./middleware/PublicRoute";
import PrivateRoute from "./middleware/PrivateRoute";
import BeneficiaryPreApprovalPage from "app/pages/BeneficiaryPreApprovalPage";



/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <PrivateRoute><Page component={DashBoardPage} /></PrivateRoute>
    },
    {
        path: "/acts",
        element: <PrivateRoute><Page component={ActsPage} /></PrivateRoute>
    },
    {
        path:"/beneficiaries-pre-approvals",
        element: <PrivateRoute><Page component={BeneficiaryPreApprovalPage} /></PrivateRoute>

    },
    {
        path: "/beneficiaries",
        element: <PrivateRoute><Page component={BeneficiaryPage} /></PrivateRoute>
    },
    {
        path: "/acts-usages",
        element: <PrivateRoute><Page component={ActsUsagePage} /></PrivateRoute>
    },
    {
        path: "/locations",
        element: <PrivateRoute><Page component={LocationPage} /></PrivateRoute>
    },
    {
        path: "/tickets",
        element: <PrivateRoute><Page component={TicketsPage} /></PrivateRoute>
    },
    {
        path: "*",
        element: <PrivateRoute><Page component={Page404} /></PrivateRoute>
    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <PublicRoute><Page layout={"solo-page"} component={Login1} /></PublicRoute>
    },
    {
        path: "*",
        element: <PublicRoute><Page layout={'solo-page'} component={Page404} /></PublicRoute>
    },
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };