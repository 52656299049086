import { Box, Card, Chip, Collapse, Grid, IconButton, Paper, TextField, Typography, styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import corporateService from "app/services/corporate";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import FilterListIcon from "@mui/icons-material/FilterList";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  useQuery,
} from '@tanstack/react-query';
import WhiteTextField from "@jumbo/components/WhiteTextField/WhiteTextField";
const MySwal = withReactContent(Swal);

const BeneficiaryPage = () => {
  const [expandFilters, setExpandFilters] = useState(true);
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');


  const { data, isError, isFetching, isLoading, refetch } =
    useQuery(
      ['beneficiaries-data',
        name,
        telephone,
        cardNumber,
        documentNumber
      ],
      async () => {
        const axiosData = await corporateService.beneficiaries(name,cardNumber,telephone,documentNumber)
        const beneficiaries = axiosData.filter(item => item.parent_id == null);
        const childrens = beneficiaries.map((it) => {
          it.subRows = axiosData.filter(itm => itm.parent_id == it.id).map((i) => { i.subRows = []; return i; })
          return it;
        })
        return childrens
      },
      { keepPreviousData: false, staleTime: 60000 * 5 },
    );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'NOME',
      },
      {
        id: 'birthdate',
        accessorKey: 'birthdate',
        header: 'DATA DE NASCIMENTO',
      },
      {
        id: 'telephone',
        accessorKey: 'telephone',
        header: 'TELEFONE',
      },
      {
        id: 'document',
        accessorKey: 'document',
        header: 'DOCUMENTO',
      },
      {
        id: 'card_number',
        accessorKey: 'card_number',
        header: 'Nº DE CARTÃO',
      },
    ],
    [],
  );
  return (
    <Box>
      <Typography sx={{ color: '#5d5d5d' }} fontWeight={900} variant="h1">Beneficiários</Typography>
      <Card>
        <MaterialReactTable
          columns={columns}
          initialState={{ showColumnFilters: false, density: 'compact', }}
          data={data ?? []}
          enableExpanding
          enableExpandAll
          enableColumnFilters={false}
          enableColumnOrdering={false}
          enableColumnFilterMode={false}
          enableHiding={false}
          enableFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          muiTablePaginationProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiBottomToolbarProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiTopToolbarProps={{ sx: { backgroundColor: '#bf2f38' } }}
          muiTableHeadRowProps={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            sx: {
              background: '#6c757d',
              "& .MuiTableCell-head": {
                color: "white",
                padding: 1,
              },
            }
          }}
          enableTableHead={true}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
          localization={MRT_Localization_PT_BR}
          muiTableBodyProps={{
            sx: (theme) => ({
              '& tr:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
              },
              '& tr:nth-of-type(even)': {
                backgroundColor: '#FFF',
              },
            }),
          }}
          enableColumnActions={false}
          enableRowActions={false}
          manualFiltering
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <IconButton onClick={() => { setExpandFilters((prev) => !prev) }}>
                      <FilterListIcon sx={{ fontSize: 20, color: "white" }} />
                    </IconButton>
                    <Typography sx={{ color: "#FFF" }}>Filtrar por</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <WhiteTextField
                          label="Nome"
                          fullWidth
                          size="small"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <WhiteTextField
                          label="Telefone"
                          fullWidth
                          size="small"
                          value={telephone}
                          onChange={(e) => setTelephone(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <WhiteTextField
                          label="Documento"
                          fullWidth
                          size="small"
                          value={documentNumber}
                          onChange={(e) => setDocumentNumber(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <WhiteTextField
                          label="Nº de Cartão"
                          fullWidth
                          size="small"
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            );
          }}
        />
      </Card>
    </Box>
  );
}

export default BeneficiaryPage;