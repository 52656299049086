import React, { useState } from 'react';
import { List, ListItemText, Collapse, Box, ListItemButton, Typography} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import ButtonMenu from '@jumbo/components/ButtonMenu/ButtonMenu';
import { createCookie } from "app/utils/appHelpers";

function NestedListItem({ item, isOdd,networkId }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  
  const handleClick = (item) => {
    if(!item.children){
      createCookie('act-subject_id',item.id,5);
      createCookie('act-voucher-id',networkId,5);
      createCookie('act-status',10,5);
      navigate('/acts-usages')
    }else{
      setOpen(!open);
    }
  };

  return (
    <>
      <ListItemButton onClick={()=>{handleClick(item)}} sx={{ mt: 1, backgroundColor: isOdd ? '#f9f9f9' : '#FFFFF' }} >
        {item.children && item.children.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}

        <ListItemText primary={
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography ml={1} minWidth={500} maxWidth={500}>{item.name}</Typography>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} minWidth={100} maxWidth={100}>
              {Number(item.total) > 0 ?
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                  <Typography color={'#5d5d5d'} sx={{ margin: 0, fontSize: '2rem' }} fontWeight={900} variant="h3">{item.total}</Typography>
                  <Typography coponent={'div'} variant="caption" sx={{ margin: 0, lineHeight: 1 }}>{Number(item.total) == 1 ? 'Utilização' : 'Utilizações'} </Typography>
                </Box>
                : null}
            </Box>
            <Box mr={2} minWidth={100} maxWidth={300} display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
              {item.latest_date ?
                <>
                  <Typography variant="caption">Última Utilização</Typography>
                  <Typography variant="caption" fontWeight={900}>{item.latest_date}</Typography>
                </>
                : null}

            </Box>
          </Box>

        } />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ paddingLeft: 2 }} component="div" disablePadding>
          {item.children &&
            item.children.length > 0 &&
            item.children.map((childItem, index) => (
              <NestedListItem
                key={childItem.id}
                item={childItem}
                isOdd={isOdd && index % 2 == 1}
                networkId={networkId}
              />
            ))}
        </List>
      </Collapse>
    </>
  );
}

const CollapseChildren = ({ items,newtorkId }) => {
  return (
    <Box>
      {items.map((item, index) => (
        <NestedListItem key={item.id} item={item}  networkId={newtorkId} isOdd={index % 2 == 0} />
      ))}
    </Box>
  )

}

export default CollapseChildren;