import React from 'react';
import { Box, Card, CardContent, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
// import {auth} from "@jumbo/services/auth/firebase/firebase";
import * as yup from 'yup';
import { Form, Formik } from "formik";
// import {useAuthSignInWithEmailAndPassword} from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../utils/constants/paths";
import { getAssetPath } from "../../utils/appHelpers";
import { storeToken } from '@jumbo/components/JumboAuthProvider/authHelpers';
import authServices from 'app/services/auth-services';
import useAuth from 'app/hooks/useAuth';

const validationSchema = yup.object({
    email: yup
        .string('Digite seu e-mail')
        .email('Entre com um email válido')
        .required('O e-mail é obrigatório'),
    password: yup
        .string('Digite sua senha')
        .required('A Senha é obrigatória'),
});

const Login1 = () => {
    const navigate = useNavigate();
    const { signin } = useAuth();

    //setAuthToken
    //temp
    const mutation = { isError: false };
    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
                <CardContent sx={{ pt: 0 }}>

                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            signin(data.email, data.password).then((dt) => {
                                console.log(dt);
                                console.log('dentro do then')
                            })
                                .catch((errr) => { })
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Box display={'flex'} justifyContent={'center'}>
                                    <img style={{objectFit:'contain'}} src={`${ASSET_IMAGES}/logo.png`} alt="Jumbo React" width={200} height={100} />

                                </Box>
                                <Typography variant='h2' align='center' mb={2}>Login</Typography>
                                {mutation.isError && <p>{mutation.error.message}</p>}
                                <Div sx={{ mb: 3, mt: 1 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                    />
                                </Div>
                                <Div sx={{ mb: 2, mt: 1 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                    />
                                </Div>
                                <Typography
                                    textAlign={"right"}
                                    variant={"body1"}
                                >
{/*                                     <Link underline="none" href="#">Forgot your password?</Link>
 */}                                </Typography>
                                <Div sx={{ mb: 1 }}>
{/*                                     <FormControlLabel control={<Checkbox />} label="Remember me" />
 */}                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting || mutation.isLoading}
                                >Entrar</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login1;
