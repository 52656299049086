import * as React from "react";
import DayJsInstance, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

DayJsInstance.extend(advancedFormat);

const styles = {
    '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiInputLabel-root': {
        color: 'white',
      },
      '& .MuiOutlinedInput-input': {
        color: 'white',
      },
      '& .MuiSvgIcon-root': {
        color: 'white'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
}

export default function BasicDatePicker(props) {
  const { name, label, value,styled, dateFormat, onChange, ...other } = props
  const convertToDefEventPara = (name, value) => ({
      target: {
          name, value
      }
  })
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateLibInstance={DayJsInstance}
    >
      <DatePicker
        inputFormat="dddd, MMMM Do YYYY"
        value={value || null}
        label={label}
        onChange={(newValue) => onChange(convertToDefEventPara(name, new Date(newValue).toString("d/M/yyyy")))}
        sx={styles}
        slotProps={{ textField: { ...other } }}
        renderInput={(params) => (
          <TextField  fullWidth  {...params}   />
        )}
      />
    </LocalizationProvider>
  );
}
