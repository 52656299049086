import { Box, Chip, Typography, Card, IconButton, Button, Grid, Collapse, MenuItem } from "@mui/material";
import React, { useState, useMemo } from "react";
import { getContrastColor } from "@jumbo/utils";
import corporateService from "app/services/corporate";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import FilterListIcon from "@mui/icons-material/FilterList";
import NewTicketModal from "./NewTicketModal";
import DetailModal from "./DetailModal";

import VisibilityIcon from '@mui/icons-material/Visibility';
import WhiteTextField from "@jumbo/components/WhiteTextField/WhiteTextField";
import BasicDatePicker from "@jumbo/components/BasicDatePicker";
import {
  useQuery,
} from '@tanstack/react-query';


const TicketsPage = () => {
  const [expandFilters, setExpandFilters] = useState(true);
  const [openModal, setOpenModal] = useState(null)
  const [openDetailModal, setOpenDetailModal] = useState(null)
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [subjects, setSubjects] = useState([])
  const [subjectId, setSubjectId] = useState('')


  const handleCloseModal = () => {
    setOpenModal(null);
  }
  const handleCloseDetailModal = () => {
    setOpenDetailModal(null);
  }

  const { data, isError, isFetching, isLoading, refetch } =
    useQuery(
      ['tickets-data',
      subjectId,
      dateStart,
      dateEnd
      ],
      async () => {
        const axiosData = await corporateService.getTickets(subjectId,dateStart,dateEnd)

        return axiosData
      },
      { keepPreviousData: false, staleTime: 60000 * 5 },
    );
  const columns = useMemo(
    () => [
      {
        id: 'user_name',
        accessorKey: 'user_name', //access nested data with dot notation
        header: 'CRIADO POR',
      },
      {
        id: 'ticket_subject_name',
        accessorKey: 'ticket_subject_name', //access nested data with dot notation
        header: 'ASSUNTO',
      },
      {
        id: 'created_at',
        accessorKey: 'created_at', //access nested data with dot notation
        header: 'DATA',
      },
      {
        id: 'name',
        accessorKey: 'entity_name', //access nested data with dot notation
        header: 'CRIADO POR',
      },
      {
        id: 'is_employee',
        accessorKey: 'ticket_status_name', //access nested data with dot notation
        header: 'ESTADO',
        Cell: ({ row }) => (
          <Box>
            <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: getContrastColor(row.original.ticket_status_color), backgroundColor: `${row.original.ticket_status_color}` }} label={row.original.ticket_status_name} />
          </Box>
        ),
      },
      {
        accessorKey: '_',
        header: 'ACÇÕES',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        Cell: ({ cell, row }) => <IconButton
          onClick={() => {
            setCurrentTicketId(row.original.id);
            setOpenDetailModal(true);
          }}><VisibilityIcon sx={{ fontSize: 20 }} />
        </IconButton>

      },

    ],
    [],
  );

  React.useEffect(() => {
    corporateService.getTicketsSubjects().then(dt => setSubjects(dt))
  }, []);
  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 1
      }}>
        <Typography sx={{ color: '#5d5d5d' }} fontWeight={900} variant="h1">Meus Pedidos</Typography>
        <Button variant="contained"
          onClick={() => {
            setOpenModal(true)
          }}
        >Novo</Button>
      </Box>
      <Card>
        <MaterialReactTable
          columns={columns}
          initialState={{ showColumnFilters: false, density: 'compact' }}
          data={data ?? []}
          enableColumnFilters={false}
          enableColumnOrdering={false}
          enableColumnFilterMode={false}
          enableHiding={false}
          enableFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          muiTablePaginationProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiBottomToolbarProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiTopToolbarProps={{ sx: { backgroundColor: '#bf2f38' } }}
          muiTableHeadRowProps={{
            sx: {
              background: '#6c757d',
              "& .MuiTableCell-head": {
                color: "white",
              },
            }
          }}
          enableTableHead={true}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}

          localization={MRT_Localization_PT_BR}

          muiTableBodyProps={{
            sx: (theme) => ({
              '& tr:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
              },
              '& tr:nth-of-type(even)': {
                backgroundColor: '#FFF',
              },
            }),
          }}
          /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <IconButton onClick={() => { setExpandFilters((prev) => !prev) }}>
                      <FilterListIcon sx={{ fontSize: 20, color: "white" }} />
                    </IconButton>
                    <Typography sx={{ color: "#FFF" }}>Filtrar por</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <WhiteTextField
                          label="Tipo"
                          fullWidth
                          size="small"
                          defaultValue={0}
                          value={subjectId}
                          select
                          onChange={(e) => setSubjectId(e.target.value)}
                        >
                          <MenuItem value={0}>Todos</MenuItem>
                          {subjects.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </WhiteTextField>
                      </Grid>
                      <Grid item xs={2}>
                        <BasicDatePicker
                          fullWidth
                          label="De"
                          value={dateStart}
                          size="small"
                          onChange={(e) => setDateStart(e.target.value)}

                        />
                      </Grid>
                      <Grid item xs={2}>
                        <BasicDatePicker
                          fullWidth
                          label="Ate"
                          value={dateEnd}
                          size="small"
                          onChange={(e) => setDateEnd(e.target.value)}
                        />
                      </Grid>

                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            );
          }}
        />
      </Card>
      {openModal ? <NewTicketModal open={openModal} handleClose={handleCloseModal} loadData={refetch} /> : null}
      {openDetailModal ? <DetailModal open={openDetailModal} handleClose={handleCloseDetailModal} ticketId={currentTicketId} /> : null}

    </Box>
  );
}

export default TicketsPage;