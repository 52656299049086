import { Person } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import VaccinesIcon from '@mui/icons-material/Vaccines';
import {
    Chart,
    LineSeries,
    ArgumentAxis,
    ValueAxis,
    Tooltip,
    BarSeries,
    Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { Palette } from '@devexpress/dx-react-chart';

import { Stack, Animation } from '@devexpress/dx-react-chart';
import { EventTracker } from '@devexpress/dx-react-chart';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import NextAppointmentsCard from './NextAppointmentsCard.js';
import LatestUsagesCard from './LatestUsagesCard';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import corporateService from "app/services/corporate.js";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ContentLoader from "@jumbo/components/ContentLoader/ContentLoader";
import {
    useQuery,
} from '@tanstack/react-query';
import CounterCard from "./StatisticCard.js";

const Root = props => (
    <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);
const Label = props => (
    <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
);



const DashBoardPage = () => {

    const { data, isError, isFetching, isLoading, refetch } =
        useQuery(
            ['dashboard-data'],
            async () => {
                const axiosData = await corporateService.dashBoardCards()
                return axiosData;
            },
            { keepPreviousData: false, staleTime: 60000 * 5 },
        );

    return (
        <Box>
            <Typography sx={{ color: '#5d5d5d' }} fontWeight={900} variant="h1">Dashboard</Typography>
            {isLoading ? null :
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Card sx={{ padding: 1 }}>
                                    <Box display={'flex'} gap={1}>
                                        <Box sx={{ display: 'flex', 'justifyContent': 'flex-start', alignItems: 'center', gap: 1, p: 2, borderRadius: 2, mb: 1, backgroundColor: '#ebc0c3' }}>
                                            <CardMembershipIcon sx={{ fontSize: '2rem', color: '#bf2f38' }} />
                                        </Box>
                                        <Box>
                                            <Typography align="justify" fontWeight={400} variant="h5">Contrato - {data?.contract_number}</Typography>
                                            <Typography align="justify" fontSize={'1rem'} fontWeight={700} variant="body1">{data?.payer_entity_name}</Typography>
                                            <Typography align="justify" fontSize={'0.9rem'} fontWeight={600} variant="">{data?.subscription_plan_name} {data?.date_start} / {data?.date_end}</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <CounterCard title={'Dias Restantes'} icon={<TimelapseIcon sx={{ fontSize: '2rem', color: '#6fa8dc' }} />} bgColor={'#d3e4f4'} value={data?.days_left} />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <CounterCard title={'Beneficiarios'} icon={<Person sx={{ fontSize: '2rem', color: '#6fa8dc' }} />} bgColor={'#d3e4f4'} value={data?.total_beneficiaries} />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <CounterCard title={'Total de Actos'} icon={<VaccinesIcon sx={{ fontSize: '2rem', color: '#BE533C' }} />} bgColor={'#EBCBC4'} value={data?.acts_sum} />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <CounterCard title={'Tipos de Actos'} icon={<VaccinesIcon sx={{ fontSize: '2rem', color: '#BE533C' }} />} bgColor={'#EBCBC4'} value={data?.total_voucher} />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <CounterCard title={'Pedidos'} icon={<ConfirmationNumberIcon sx={{ fontSize: '2rem', color: '#7CC49A' }} />} bgColor={'#d7ede0'} value={data?.total_ticket} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardHeader title={'Utilizações'} />
                            <CardContent>
                                <Chart height={300} data={data.validationsChart}>
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <LineSeries valueField="total" argumentField="Month" />
                                    <EventTracker />
                                    <Tooltip />
                                    <Animation />
                                </Chart>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LatestUsagesCard data={data.latestUsages} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NextAppointmentsCard data={data.nextAppointments} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardHeader title={'Utilizações dos Actos'} />
                            <Chart
                                data={data.voucherUsageChart}
                                height={380}
                            >
                                <Palette scheme={['#40C060', '#D95749']} />
                                <ArgumentAxis />
                                <ValueAxis />
                                <BarSeries
                                    name="Total"
                                    valueField="total"
                                    argumentField="name"
                                    color="#40C060"
                                />
                                <BarSeries
                                    name="Usado"
                                    valueField="used"
                                    argumentField="name"
                                    color="#D95749"
                                />
                                <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                                <EventTracker />
                                <Tooltip />
                                <Stack />
                                <Animation />
                            </Chart>
                        </Card>
                    </Grid>
                </Grid>
            }
            <ContentLoader open={isLoading} />
        </Box>

    )

}

export default DashBoardPage