import Cookies from "js-cookie";
import {USE_IMAGE_PLACEHOLDERS} from "./constants/paths";

export const getAssetPath = (url, size) => {
    if(USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

export const getCookieValue =(cookieName)=>{
    let value = Cookies.get(cookieName);
    if(value != null || value != undefined){
        return value;
    }else{
        return null
    }

}

export const createCookie = (name,data,expiration)=>{
    Cookies.set(name,data,{ expires: new Date(new Date().getTime() + expiration * 1000) });
}
export const convertToServertDateFormat=(inputDate)=> {
    let valid = Symbol.for(new Date(inputDate)) === Symbol.for("Invalid Date") 
    if(!valid){
        const newDate = new Date(inputDate);
        const convertedDate = newDate.toLocaleDateString("en-CA");
        return convertedDate;           
    }else{
        return ''
    }

  
  }
