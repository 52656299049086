import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, DialogContent, Typography, FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, Autocomplete, TextField, Grid, MenuItem, IconButton } from "@mui/material";
import { Formik, Form, getIn, FieldArray } from 'formik'
import * as yup from 'yup';
import corporateService from "app/services/corporate";
import DatePickerField from "@jumbo/components/DatePickerField";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import moment from "moment";
import ContentLoader from "@jumbo/components/ContentLoader/ContentLoader";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const { format, parseISO } = require('date-fns');
const MySwal = withReactContent(Swal);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function formatDate(dateString) {
    var date = new Date(dateString);

    var day = date.getDate();
    var month = date.getMonth() + 1; // Add 1 to the month since it is zero-based
    var year = date.getFullYear();

    // Pad the day and month with leading zeros if necessary
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    var formattedDate = day + '-' + month + '-' + year;
    return formattedDate;
}

const validationSchema = yup.object({
    /*     price: yup
            .string()
            .required(),
        size: yup.string().required() */
});
//    const [value, setValue] = React.useState(new Date('2022-06-04T21:11:54'));

const BeneficiaryEditModal = ({ open, handleClose, beneficiary }) => {
    const [zones, setZones] = useState([]);
    const [locations, setLocations] = useState([]);
    const [data, setData] = useState({});
    const [documentTypes, setDocumentTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
        name: '',
        birthdate:'',
        gender: '',
        profession: '',
        contacts: [],
        documents: [],
    })
    useEffect(() => {
        //  getAvailableVoucher()
        getDocuments()
        getBeneficiary(beneficiary.entity_id)

    }, []);


    const getDocuments = () => {
        corporateService.getDocuments().then((data) => {
            setDocumentTypes(data.data);

        })
    }

    const getBeneficiary = (id) => {
        corporateService.getBeneficiary(id).then(({ data }) => {
            console.log(data.birthdate)
            console.log('format' + moment(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY').toString());
            //birthdate:moment(data.birthdate,'YYYY-MM-DD').format('DD/MM/YYYY'),
            setInitialValues({ ...initialValues,birthdate:new Date(data.birthdate), contacts: data.contacts, documents: data.documents, gender: data.gender, name: data.name, profession: data.profession })
            setIsLoading(false)
            //birthdate:format(parseISO(data.birthdate), "dd/MM/yyyy"),
        })
    }



    return (
        <React.Fragment>
            {isLoading ? null :
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                    maxWidth={'md'}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={(data, { setSubmitting }) => {
                            const birth = moment(data.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                            data.birthdate = birth;
                            corporateService.updateBeneficiary(beneficiary.entity_id,data)
                            .then((dt)=>{
                                handleClose()
                                MySwal.fire({
                                    icon: 'success',
                                    title: 'Atualização',
                                    text: 'Beneficiário Atualizado'
                                })
                            })
                            .catch((err)=>{
                                MySwal.fire({
                                    icon: 'error',
                                    title: 'Erro',
                                    text: 'Erro ao atualizar Beneficiário'
                                })
                            })
                        }}
                    >
                        {({ isSubmitting, values, setFieldValue, handleChange, handleBlur, errors, touched }) => (
                            <Form id="entity-form" style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <DialogTitle>Editar Beneficiário - {beneficiary.name}</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Nome</Typography>
                                            <TextField
                                                variant="outlined"
                                                name="name"
                                                fullWidth
                                                onChange={handleChange}
                                                value={values.name}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle2">Data de Nascimento</Typography>
                                            <DatePickerField
                                                fullWidth
                                                slotProps={{ textField: { fullWidth: true } }}
                                                value={values.birthdate}
                                                onChange={(e) => { console.log(e.target.value); setFieldValue('birthdate', e.target.value) }}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="subtitle2">Sexo</Typography>
                                            <TextField
                                                select
                                                name="gender"
                                                defaultValue={''}
                                                value={values.gender}
                                                onChange={handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value={''}></MenuItem>
                                                <MenuItem value={'M'}>M</MenuItem>
                                                <MenuItem value={'F'}>F</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Profissão</Typography>
                                            <TextField
                                                variant="outlined"
                                                name="profession"
                                                fullWidth
                                                onChange={handleChange}
                                                value={values.profession}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Contactos</Typography>
                                            <FieldArray name="contacts">
                                                {({ push, remove }) => (
                                                    <React.Fragment>
                                                        {values.contacts.map((_contact, index) => {
                                                            const contact = `contacts[${index}].contact`;
                                                            const touchedContact = getIn(touched, contact);
                                                            const errorContact = getIn(errors, contact);

                                                            const contactTypeId = `contacts[${index}].entity_contact_type_id`;
                                                            const touchedContactTypeId = getIn(touched, contactTypeId);
                                                            const errorContactTypeId = getIn(errors, contactTypeId);

                                                            const countryId = `contacts[${index}].country_id`;
                                                            const touchedCountryId = getIn(touched, countryId);
                                                            const errorCountryId = getIn(errors, countryId);
                                                            const isPrimary = `contacts[${index}].is_primary`;

                                                            return (
                                                                <Grid container spacing={2} mb={1.5}>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            name={contact}
                                                                            value={_contact.contact}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                touchedContact && errorContact
                                                                                    ? errorContact
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedContact && errorContact)}

                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            select
                                                                            defaultValue={6}
                                                                            fullWidth
                                                                            label="Tipo de Contacto"
                                                                            name={contactTypeId}
                                                                            value={_contact.entity_contact_type_id}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                touchedContactTypeId && errorContactTypeId
                                                                                    ? errorContactTypeId
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedContactTypeId && errorContactTypeId)}
                                                                        >
                                                                            <MenuItem value={2}>Telefone</MenuItem>
                                                                            <MenuItem value={4}>Email</MenuItem>
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            select
                                                                            defaultValue={6}
                                                                            fullWidth
                                                                            label="País"
                                                                            name={countryId}
                                                                            value={_contact.country_id}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                touchedCountryId && errorCountryId
                                                                                    ? errorCountryId
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedCountryId && errorCountryId)}
                                                                        >
                                                                            <MenuItem value={''}></MenuItem>
                                                                            <MenuItem value={6}>Angola</MenuItem>
                                                                            <MenuItem value={171}>Portugal</MenuItem>
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <IconButton sx={{ mt: 1 }} onClick={() => { remove(index) }}>
                                                                            <RemoveCircleIcon sx={{ color: '#FF3333' }} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                        {values.contacts.length < 4 ?
                                                            <IconButton sx={{ color: '#4BB543' }}
                                                                onClick={() => push({ id: -1, entity_contact_type_id: "", country_id: 6, contact: "", is_primary: 0 })}>
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                            : null}
                                                    </React.Fragment>
                                                )}
                                            </FieldArray>
                                            {typeof errors.contacts === "string" ? (
                                                <Typography color="error">{errors.contacts}</Typography>
                                            ) : null}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Documentos</Typography>
                                            <FieldArray name="documents">
                                                {({ push, remove }) => (
                                                    <React.Fragment>
                                                        {values.documents.map((_document, index) => {
                                                            const document = `documents[${index}].value`;
                                                            const touchedDocument = getIn(touched, document);
                                                            const errorDocument = getIn(errors, document);

                                                            const entityDocumentType = `documents[${index}].document_type_id`;
                                                            const touchedDocumentTypeId = getIn(touched, entityDocumentType);
                                                            const errorDocumentTypeId = getIn(errors, entityDocumentType);

                                                            const countryId = `documents[${index}].country_id`;
                                                            const touchedCountryId = getIn(touched, countryId);
                                                            const errorCountryId = getIn(errors, countryId);

                                                            return (
                                                                <Grid container spacing={2} mb={1.5}>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label="Nº de Identificação"
                                                                            name={document}
                                                                            value={_document.value}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                touchedDocument && errorDocument
                                                                                    ? errorDocument
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedDocument && errorDocument)}

                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            select
                                                                            defaultValue={6}
                                                                            fullWidth
                                                                            label="Tipo de Documento"
                                                                            name={entityDocumentType}
                                                                            value={_document.document_type_id}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                touchedDocumentTypeId && errorDocumentTypeId
                                                                                    ? errorDocumentTypeId
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedDocumentTypeId && errorDocumentTypeId)}
                                                                        >
                                                                            {documentTypes?.map((it, idx) => (
                                                                                <MenuItem value={it.id} key={it.id}>{it.name}</MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            select
                                                                            defaultValue={6}
                                                                            fullWidth
                                                                            label="País"
                                                                            name={countryId}
                                                                            value={_document.country_id}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                touchedCountryId && errorCountryId
                                                                                    ? errorCountryId
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedCountryId && errorCountryId)}
                                                                        >
                                                                            <MenuItem value={''}></MenuItem>
                                                                            <MenuItem value={6}>Angola</MenuItem>
                                                                            <MenuItem value={171}>Portugal</MenuItem>
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <IconButton sx={{ mt: 1 }} onClick={() => { remove(index) }}>
                                                                            <RemoveCircleIcon sx={{ color: '#FF3333' }} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                        {values.documents.length < 4 ?
                                                            <IconButton sx={{ color: '#4BB543' }}
                                                                onClick={() => push({ id: -1, document_type_id: "", country_id: 6, contact: "", is_primary: 0 })}>
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                            : null}
                                                    </React.Fragment>
                                                )}
                                            </FieldArray>
                                            {
                                                typeof errors.documents === "string" ? (
                                                    <Typography color="error">{errors.documents}</Typography>
                                                ) : null
                                            }
                                        </Grid>

                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Cancelar</Button>
                                    <Button type="submit" form="entity-form">Gravar</Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            }
            <ContentLoader open={isLoading} />
        </React.Fragment>
    )

}

export default BeneficiaryEditModal;