import Page from "@jumbo/shared/Page/Page";
import { Box, Typography, Card, IconButton, Autocomplete, Checkbox, TextField, Paper, Grid, Collapse, MenuItem } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import corporateService from "app/services/corporate";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import CheckIcon from "@mui/icons-material/Check";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSearchParams } from "react-router-dom";
import WhiteTextField from "@jumbo/components/WhiteTextField/WhiteTextField";
import {
  useQuery,
} from '@tanstack/react-query';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubjectDetailModal from './SubjectDetailModal'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LocationPage = () => {
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchParams] = useSearchParams();
  const [expandFilters, setExpandFilters] = useState(true);
  const [openSubjectModal, setOpenSubjectModal] = useState(false);
  const [currentVoucher, setCurrentVoucher] = useState(null)
  const [currentLocationId, setCurrentLocationId] = useState(null)
  const [zones,setZones] = useState([]);
  const [searchForm, setSearchForm] = useState({
    zoneId: '',
    name: '',
    hasVoucher: 0,
    network: searchParams.get('network') || 0
  })
  const { data, isError, isFetching, isLoading, refetch } =
    useQuery(
      ['locations-data',
        searchForm,
      ],
      async () => {
        const axiosData = await corporateService.locations2(searchForm)

        let auxColumns = [{
          id: 'name',
          accessorKey: 'name', //access nested data with dot notation
          header: 'NOME',
        },
        {
          id: 'address',
          accessorKey: 'address', //access nested data with dot notation
          header: 'ENDEREÇO',
        },
        {
          id: 'zone_name',
          accessorKey: 'zone_name', //access nested data with dot notation
          header: 'PROVINCIA',
        }];
        axiosData.columns.map((item, index) => {
          auxColumns.push({
            id: item.field,
            accessorKey: item.field,
            header: item.title,
            Cell: ({ row }) => (
              <Box>
                {row.original[item.field] == 1 ?
                  <Box display={'flex'} alignItems={'center'} >
                    <CheckIcon />
                    <IconButton onClick={() => {
                      setCurrentLocationId(row.original.id)
                      setCurrentVoucher(item.field.charAt(item.field.length - 1))
                      setOpenSubjectModal(true)
                    }}>
                      <VisibilityIcon />
                    </IconButton>
                  </Box> :
                  <CloseIcon />}
              </Box>
            )
          })
        })
        setColumns(auxColumns);
        return axiosData.locations;
      },
      { keepPreviousData: false },
    );

  const handleCloseSubjectModal = () => {
    setOpenSubjectModal(false);
  }

  useEffect(()=>{
    corporateService.getProvinces().then((dt)=>setZones(dt))
  },[])

  return (
    <Box>
      <Typography sx={{ color: '#5d5d5d' }} fontWeight={900} variant="h1">Locais de Prestação</Typography>
      <Card>
        <MaterialReactTable
          columns={columns}
          initialState={{ showColumnFilters: false, density: 'compact' }}
          data={data ?? []}
          enableColumnFilters={false}
          enableColumnOrdering={false}
          enableColumnFilterMode={false}
          enableHiding={false}
          enableFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnActions={false}
          muiTablePaginationProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiBottomToolbarProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiTopToolbarProps={{ sx: { backgroundColor: '#bf2f38' } }}
          muiTableHeadRowProps={{
            sx: {
              background: '#6c757d',
              "& .MuiTableCell-head": {
                color: "white",
              },
            }
          }}
          enableTableHead={true}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}

          localization={MRT_Localization_PT_BR}

          muiTableBodyProps={{
            sx: (theme) => ({
              '& tr:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
              },
              '& tr:nth-of-type(even)': {
                backgroundColor: '#FFF',
              },
            }),
          }}
          /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
          renderTopToolbarCustomActions={({ table }) => {
            const handleDeactivate = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                alert('deactivating ' + row.getValue('name'));
              });
            };

            const handleActivate = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                alert('activating ' + row.getValue('name'));
              });
            };

            const handleContact = () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                alert('contact ' + row.getValue('name'));
              });
            };

            return (
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <IconButton onClick={() => { setExpandFilters((prev) => !prev) }}>
                      <FilterListIcon sx={{ fontSize: 20, color: "white" }} />
                    </IconButton>
                    <Typography sx={{ color: "#FFF" }}>Filtrar por</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <WhiteTextField
                          label="Tipo"
                          fullWidth
                          size="small"
                          select
                          value={searchForm.hasVoucher}
                          onChange={(e) => setSearchForm(prev => ({ ...prev, hasVoucher: e.target.value }))}
                        >
                          <MenuItem value={0}>Geral</MenuItem>
                          <MenuItem value={1}>Actos Incluidos</MenuItem>
                        </WhiteTextField>
                      </Grid>
                      <Grid item xs={3}>
                        <WhiteTextField
                          label="Nome"
                          fullWidth
                          size="small"
                          value={searchForm.name}
                          onChange={(e) => setSearchForm(prev => ({ ...prev, name: e.target.value }))}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          fullWidth
                          id="combo-box-demo"
                          options={zones}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, v, r) => {
                             if (r == 'selectOption') {
                              if (Number(v.id) > 0) {
                                setSearchForm(prev => ({ ...prev, zoneId: v.id }))
                              }
                            } else {
                              setSearchForm(prev => ({ ...prev, zoneId: '' }))
                            } 
                          }}
                          size="small"
                          renderInput={(params) => <WhiteTextField  {...params} label="Provincia" />}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            );
          }}
        />
      </Card>
      {openSubjectModal ? <SubjectDetailModal open={openSubjectModal} handleClose={handleCloseSubjectModal} locationId={currentLocationId} voucherId={currentVoucher} /> : null}

    </Box>
  );
}

export default LocationPage;