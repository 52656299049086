import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, DialogContent, Typography, FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, Autocomplete, TextField, Grid, MenuItem, Tooltip, Box, Alert } from "@mui/material";
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import corporateService from "app/services/corporate";
import DatePickerField from "@jumbo/components/DatePickerField";
import TimePickerField from "@jumbo/components/TimePickerField";

import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import JumboDropzone from "@jumbo/components/JumboDropzone";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Dropzone from "react-dropzone";

const MySwal = withReactContent(Swal);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    location_id: yup
        .string()
        .required(),
    subject_id: yup.string().required(),
    appointment_date: yup.string().required(),
    voucher_id: yup.string().required(),

});



const AppointmentScheduleModal = ({ open, handleClose }) => {
    const [vouchers, setVouchers] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [zones, setZones] = useState([]);
    const [subjectValue, setSubjectValue] = React.useState(null);
    const [locationValue, setLocatioValue] = React.useState(null);
    const [beneficiaryValue, setBeneficiaryValue] = React.useState(null);
    const [beneficiaries, setBeneficiaries] = React.useState([]);
    const [locations, setLocations] = useState([]);
    const [showDropzone, setShowDropZone] = useState(false);
    const [initialValues, setInitialValues] = useState({
        voucher_id: '',
        subject_id: '',
        location_id: '',
        entity_id: '',
        customer_subscription_has_entity:'',
        zone_id: '',
        appointment_date: '',
        appointment_time: '',
        notes: '',
        attachments: []
    })
    useEffect(() => {
        // getAvailableVoucher()
        getBeneficiaries();
    }, []);

    const getBeneficiaries = async () => {
        try {
            const dt = await corporateService.beneficiariesPreAprovalls();
            setBeneficiaries(dt.beneficiaries);
        } catch (error) {

        }


    }
    const getAvailableVoucher = (customer_subscription_has_entity) => {
        corporateService.beneficiaryVouchers(customer_subscription_has_entity).then((data) => {
            if (data.length == 1) {
                setInitialValues({ ...initialValues, voucher_id: data[0].id })
                setVouchers(data);
                getZones(data[0].id);
            } else {
                setVouchers(data);
            }
        })
    }

    const getVoucherSubjects = (voucherId, zoneId) => {
        corporateService.getVouchersSubjects(voucherId, zoneId).then((data) => {
            setSubjects(data);
        })
    }

    const getZones = (voucherId) => {
        corporateService.getVoucherZones(voucherId).then((data) => {
            setZones(data);
        })
    }

    const getLocations = (voucherId, subjectId, zoneId) => {
        corporateService.locations(voucherId, subjectId, zoneId).then((data) => {
            setLocations(data);
        })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth={'md'}
        >
            <Formik
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(data, { setSubmitting }) => {
                    let aux = new Date(data.appointment_date).toLocaleDateString('en-CA');
                    data.datetime = aux + ' ' + data.appointment_time.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
                    const formData = new FormData();
                    data.attachments.map(item => {
                        formData.append('files[]', item);
                    })
                    console.log(data)
                    data.attachments = null;
                    formData.append('data', JSON.stringify(data));
                    corporateService.createBook(formData).then((data) => {
                        MySwal.fire({
                            icon: 'success',
                            title: 'alert',
                            text: 'mensagem'
                        })
                    }).catch(()=>{
                        MySwal.fire({
                            icon: 'error',
                            title: 'Erro!',
                            text: 'Erro ao Marcar Consulta.'
                        }) 
                    })
                }}
            >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <Form style={{ textAlign: 'left' }} noValidate autoComplete='off' id="appointment-form">
                        <DialogTitle>Novo Agendamento</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Escolha o Beneficiario</Typography>
                                    <Autocomplete
                                        options={beneficiaries}
                                        getOptionLabel={(option)=>option.name}
                                        id="disable-clearable"
                                        disableClearable
                                        onChange={(event, newValue) => {
                                            setFieldValue('entity_id', newValue.entity_id) 
                                            getAvailableVoucher(newValue.customer_subscription_has_entity);
                                            setFieldValue('customer_subscription_has_entity',newValue.customer_subscription_has_entity)
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Escolha o Acto</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={values.voucher_id}
                                            onChange={(e) => {
                                                setFieldValue('voucher_id', e.target.value)
                                                setFieldValue('extra', () => vouchers.filter(item => item.id = e.target.value))
                                                setFieldValue('appointment_date', '');
                                                setFieldValue('zone_id', '');
                                                setFieldValue('notes', '');
                                                setFieldValue('location_id', '');
                                                setFieldValue('subject_id', '');
                                                setSubjectValue(null)
                                                setLocatioValue(null)
                                                getZones(e.target.value)

                                            }}
                                        >
                                            {vouchers?.map((item, index) => (
                                                <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={
                                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                                        {(Number(item.total_used) >= Number(item.pre_approved)) ?
                                                            <Tooltip title="Utilizações Pré-aprovadas Esgotadas">
                                                                <WarningIcon sx={{ color: '#eed202' }} />
                                                            </Tooltip> :
                                                            <Tooltip title="Ultilzação disponível">
                                                                <CheckCircleIcon sx={{ color: '#4BB543' }} />
                                                            </Tooltip>
                                                        }
                                                        <Typography>( {Number(item.total_used)} / {Number(item.pre_approved)} ) </Typography>
                                                        <Typography>{item.name}</Typography>

                                                    </Box>} />
                                            ))}

                                            {vouchers?.length == 0 ?
                                                <Alert severity="warning">Beneficiário sem Actos Incluidos!</Alert>
                                                : null
                                            }
                                        </RadioGroup>
                                        <Typography>
                                        </Typography>
                                    </FormControl>

                                </Grid>
                                {values.voucher_id ?
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Provincia</Typography>
                                        <TextField
                                            select
                                            defaultValue=""
                                            variant="outlined"
                                            fullWidth
                                            value={values.zone_id}
                                            onChange={(e) => {
                                                setFieldValue('zone_id', e.target.value);
                                                getVoucherSubjects(values.voucher_id, e.target.value)
                                            }}
                                        >
                                            {zones?.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    : null}
                                {values.zone_id ?
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Especialidade</Typography>
                                        <Autocomplete
                                            options={subjects}
                                            id="controlled-demo"
                                            value={subjectValue}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, newValue) => {
                                                setSubjectValue(newValue);
                                                setFieldValue('subject_id', newValue.id)
                                                getLocations(values.voucher_id, values.subject_id, values.zone_id)
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="" variant="outlined" />
                                            )}
                                        />
                                    </Grid>
                                    : null}
                                {values.subject_id ?
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Local de Prestação</Typography>
                                        <Autocomplete
                                            options={locations}
                                            id="controlled-demo"
                                            value={locationValue}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, newValue) => {
                                                setLocatioValue(newValue);
                                                setFieldValue('location_id', newValue.id)

                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="" variant="outlined" />
                                            )}
                                        />
                                    </Grid>
                                    : null}
                                {values.location_id ?
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle2">Data/Hora Pretendida</Typography>
                                        <Box display={'flex'} gap={1}>
                                            <DatePickerField
                                                disablePast={true}
                                                slotProps={{ textField: { fullWidth: true } }}
                                                onChange={(e) => { setFieldValue('appointment_date', e.target.value) }}
                                            />
                                            <TimePickerField
                                                onChange={(e) => { setFieldValue('appointment_time', e.target.value) }}
                                            />
                                        </Box>

                                    </Grid>
                                    : null}
                                {
                                    values.appointment_date ?
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Notas/Observações</Typography>
                                            <TextField
                                                fullWidth
                                                multiline
                                                value={values.notes}
                                                onChange={(e) => { setFieldValue('notes', e.target.value) }}
                                                minRows={4}
                                                maxRows={8}
                                            />
                                        </Grid>
                                        : null
                                }

                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Anexos (Prescrições,Receitas Médicas)</Typography>
                                    <Box
                                        gridColumn="span 4"
                                        border={'1px solid #A3A3A3'}
                                        borderRadius="5px"
                                        p="1rem"
                                    >
                                        <Dropzone
                                            acceptedFiles=".jpg,.pdf,.jpeg,.png"
                                            multiple={true}
                                            maxFiles={5}
                                            onDrop={(acceptedFiles) =>
                                                setFieldValue("attachments", acceptedFiles)
                                            }
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <Box
                                                    {...getRootProps()}
                                                    border={`2px dashed #bf2f38`}
                                                    p="1rem"
                                                    sx={{ "&:hover": { cursor: "pointer" } }}
                                                >
                                                    <input {...getInputProps()} />
                                                    {!values.attachments ? (
                                                        <p>Adicione um anexo</p>
                                                    ) : (
                                                        <Box sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}>
                                                            <Box>
                                                                {values.attachments?.map((it, idx) => (
                                                                    <Typography key={idx}>{it.path}</Typography>

                                                                ))}
                                                            </Box>
                                                            <EditOutlinedIcon />
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Dropzone>
                                    </Box>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancelar</Button>
                            {vouchers?.length > 0 ? <Button type="submit" form="appointment-form">Gravar</Button> : null}
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )

}

export default AppointmentScheduleModal;