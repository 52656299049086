import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, DialogContent, Typography, FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, Autocomplete, TextField, Grid, MenuItem, Tooltip, Box, Alert, Chip } from "@mui/material";
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import corporateService from "app/services/corporate";
import DatePickerField from "@jumbo/components/DatePickerField";
import TimePickerField from "@jumbo/components/TimePickerField";

import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import JumboDropzone from "@jumbo/components/JumboDropzone";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Dropzone from "react-dropzone";
import { getContrastColor } from "@jumbo/utils";

const MySwal = withReactContent(Swal);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    location_id: yup
        .string()
        .required(),
    subject_id: yup.string().required(),
    appointment_date: yup.string().required(),
    voucher_id: yup.string().required(),

});



const TicketDetailModal = ({ open, handleClose, ticketId }) => {
    console.log(ticketId)
    const [data, setData] = useState({});
    useEffect(() => {
        getTicketDetails()
    }, []);


    const getTicketDetails = () => {
        corporateService.getTicketById(ticketId).then((data) => {
            setData(data);
        })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth={'md'}
        >
            <Grid p={2} container>
                <Grid item md={3}>
                    <Typography variant="h5" color="primary">Assunto</Typography>
                    <Typography>{data?.ticket_subject_name}</Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography variant="h5" color="primary">Estado</Typography>
                    <Box display={'flex'} gap={1}  alignItems={'center'}>
                        {data?.id ?
                            <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: getContrastColor(data?.ticket_status_color), backgroundColor: `${data?.ticket_status_color}` }} label={data.ticket_status_name} />
                            : null}
                    </Box>
                </Grid>
                <Grid item md={3}>
                    <Typography variant="h5" color="primary">Criado Por</Typography>
                    <Typography>{data?.user_name}</Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography variant="h5" color="primary">Data de Criação</Typography>
                    <Typography>{data?.created_at}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary">Notas</Typography>
                    <Typography>{data?.notes ?? 'N/A'}</Typography>
                </Grid>
            </Grid>
        </Dialog>
    )

}

export default TicketDetailModal;