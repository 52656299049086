import { Box, Chip, Typography, Card, IconButton, TextField, Grid, styled, Button, Autocomplete, MenuItem } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { getContrastColor } from "@jumbo/utils";
import corporateService from "app/services/corporate";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import CheckIcon from "@mui/icons-material/Check";
import FilterListIcon from "@mui/icons-material/FilterList";
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Collapse } from '@mui/material';
import { useSearchParams } from "react-router-dom";
import {
  useQuery,
} from '@tanstack/react-query';
import AppointmentScheduleModal from "./AppointmentScheduleModal";
import WhiteTextField from "@jumbo/components/WhiteTextField/WhiteTextField";
import BasicDatePicker from "@jumbo/components/BasicDatePicker";
import { getCookieValue } from "app/utils/appHelpers";
import { ClearAll } from "@mui/icons-material";

const ActsUsagePage = () => {
  const [expandFilters, setExpandFilters] = useState(true);
  const [openModal, setOpenModal] = useState(null);
  const [searchParams] = useSearchParams();
  const [network, setNetwork] = useState();
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [bookstatus, setBookstatus] = useState([]);
  const [bookSubjects, setBookSubjects] = useState([]);
  const [appointmentBookStatusId,setAppointmentBookStatusId] = useState(0);
  const [subjectId, setSubjectId] = useState(searchParams.get('subject_id') || '');
  const [name, setName] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } =
    useQuery(
      [
        'acts-usage',
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        cardNumber,
        name,
        dateStart,
        dateEnd,
        network,
        subjectId,
        appointmentBookStatusId
      ],
      async () => {
        console.log(sorting);
        const axiosData = await corporateService.getActs(pagination, sorting, name, cardNumber, dateStart, dateEnd, network, subjectId,appointmentBookStatusId)
        return axiosData;
      },
      {},
    );

  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'entity_name', //access nested data with dot notation
        header: 'NOME',
      },
      {
        id: 'card_number',
        accessorKey: 'card_number', //access nested data with dot notation
        header: 'CARTÃO',
      },
      {
        id: 'is_employee',
        accessorKey: 'is_employee', //access nested data with dot notation
        header: 'COLABORADOR',
        Cell: ({ row }) => (
          <Box>{row.original.is_employee ? <CheckIcon sx={{ fontSize: 16 }} /> : null}</Box>
        )
      },
      {
        id: 'appointment_date',
        accessorKey: 'appointment_date', //access nested data with dot notation
        header: 'DATA/HORA',
      },
      {
        id: 'appointment_booking_status_name',
        accessorKey: 'appointment_booking_status_name', //access nested data with dot notation
        header: 'ESTADO',
        Cell: ({ row }) => (
          <Box>
            <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: getContrastColor(row.original.appointment_booking_status_color), backgroundColor: `${row.original.appointment_booking_status_color}` }} label={row.original.appointment_booking_status_name} />
          </Box>
        ),

      },
      {
        id: 'subject_name',
        accessorKey: 'subject_name', //access nested data with dot notation
        header: 'ATO/ESPECIALIDADE',
        Cell: ({ row }) => (
          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
            <Chip sx={{ fontWeight: 500, fontSize: '.700rem', color: getContrastColor('#FFF'), backgroundColor: '#a9a9a9' }} label={row.original.subject_name} />
            {row.original.needs_attachment ? <AttachmentIcon /> : null}
          </Box>
        )

      },
      {
        id: 'location_name',
        accessorKey: 'location_name',
        header: 'CLINICA',

      },
    ],
    [],
  );

  const handleCloseModal = () => {
    setOpenModal(null)
  }
  const searchSubjects = (name='') =>{
    corporateService.bookSubjects(name).then((dt)=>{setBookSubjects(dt)})
  }
  useEffect(()=>{
    corporateService.bookStatus().then((dt)=>{setBookstatus(dt)})
    searchSubjects()
  },[])

  const clearForm = ()=>{
    setCardNumber('')
    setName('')
    setDateEnd('')
    setDateStart('')
    //setNetwork('')
    setSubjectId('')
    setAppointmentBookStatusId(0)
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 1
      }}>
        <Typography sx={{ color: '#5d5d5d' }} fontWeight={900} variant="h1">Utilizações de Actos</Typography>
        <Button variant="contained"
          onClick={() => {
            setOpenModal(true)
          }}
        >Novo</Button>
      </Box>
      <Box mb={2} />
      <Card>
        <MaterialReactTable
          columns={columns}
          initialState={{ showColumnFilters: false, density: 'compact' }}
          data={data?.rows ?? []}
          enableColumnFilters={false}
          enableColumnOrdering={false}
          enableColumnFilterMode={false}
          enableHiding={false}
          enableFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          rowCount={data?.totalRowCount ?? 0}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
          }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                color: 'error',
                children: 'Erro ao tentar carregar lista.',
              }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTablePaginationProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiBottomToolbarProps={{ sx: { backgroundColor: '#FFFFFF' } }}
          muiTopToolbarProps={{ sx: { backgroundColor: '#bf2f38' } }}
          muiTableHeadRowProps={{
            sx: {
              background: '#6c757d',
              "& .MuiTableCell-head": {
                color: "white",
              },
            }
          }}
          enableTableHead={true}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
          manualFiltering
          manualPagination
          manualSorting
          localization={MRT_Localization_PT_BR}
          muiTableBodyProps={{
            sx: (theme) => ({
              '& tr:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
              },
              '& tr:nth-of-type(even)': {
                backgroundColor: '#FFF',
              },
            }),
          }}
          /*   muiTableBodyCellProps={{ sx: { backgroundColor: '#fff' } }} */
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Grid container>
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <IconButton onClick={() => { setExpandFilters((prev) => !prev) }}>
                      <FilterListIcon sx={{ fontSize: 20, color: "white" }} />
                    </IconButton>
                    <Typography sx={{ color: "#FFF" }}>Filtrar por</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={expandFilters} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <WhiteTextField
                          label="Nome"
                          fullWidth
                          value={name}
                          size="small"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Autocomplete
                          fullWidth
                          id="combo-box-demo"
                          options={bookSubjects}
                          getOptionLabel={(option) => option.name}
                          onChange={(e,v,r)=>{
                            if(r =='selectOption'){
                              if(Number(v.id)>0){
                                setSubjectId(v.id)
                              }
                            }else{
                              setSubjectId('')
                            }
                          }}
                          size="small"
                          renderInput={(params) => <WhiteTextField  {...params} label="Especialidade" onChange={(e)=>{
                            searchSubjects(e.target.value)
                          }} />}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <WhiteTextField
                          label="Tipo"
                          fullWidth
                          size="small"
                          defaultValue={0}
                          value={appointmentBookStatusId}
                          select
                           onChange={(e) => setAppointmentBookStatusId(e.target.value)}
                         >
                          <MenuItem value={0}>Todos</MenuItem>
                          {bookstatus.map((item,index)=>(
                               <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </WhiteTextField>
                      </Grid>
                      <Grid item xs={2}>
                        <WhiteTextField
                          label="Nº de Cartão"
                          fullWidth
                          value={cardNumber}
                          size="small"
                          onChange={(e) => setCardNumber(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <BasicDatePicker
                          fullWidth
                          label="Inicio"
                          value={dateStart}
                          size="small"
                          onChange={(e) => setDateStart(e.target.value)}

                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <BasicDatePicker
                          fullWidth
                          label="Fim"
                          value={dateEnd}
                          size="small"
                          onChange={(e) => setDateEnd(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                          <IconButton onClick={()=>{clearForm()}}>
                            <ClearAll sx={{ color:'white' }}/>
                          </IconButton>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>

            );
          }}
        />
      </Card>
      {openModal && <AppointmentScheduleModal open={openModal} handleClose={handleCloseModal} />}
    </Box>
  );
}

export default ActsUsagePage;