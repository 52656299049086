import { convertToServertDateFormat } from "../utils/appHelpers";
import { getCookieValue } from "../utils/appHelpers";

import jwtAuthAxios from "./auth/jwtAuth";

const clear = (value) => {
    if (value == null) {
        return ''
    } else {
        return value
    }
}

const corporateService = {};

corporateService.getVouchers = async () => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get("vouchers");
    return data;
};

corporateService.getVouchersSubjects = async (id, zoneId) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get("vouchers/" + id + "/subjects?zone_id=" + zoneId);
    return data;
};



//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
corporateService.getActs = async (pagination, sorting, name = '', cardNumber = '', dateStart = '', dateEnd = '', netWork = 0, subjectId = '', appointmentBookStatusId = '') => {
    const url = "/acts?"
        + 'start=' + pagination.pageIndex * pagination.pageSize
        + '&limit=' + pagination.pageSize
        + '&sorting=' + (sorting ? JSON.stringify(sorting) : JSON.stringify([]))
        + '&filter_card=' + cardNumber
        + '&filter_voucher=' + (getCookieValue('act-voucher-id'))
        + '&filter_network=' + (getCookieValue('act-newtork-id'))
        + '&filter_date_start=' + convertToServertDateFormat(dateStart)
        + '&filter_date_end=' + convertToServertDateFormat(dateEnd)
        + '&filter_subject=' + (getCookieValue('act-subject_id') || clear(subjectId))
        + '&filter_appointment_status=' + (getCookieValue('act-status') || clear(appointmentBookStatusId))
        + '&filter_name=' + clear(name)

    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get(url);
    return data;
}

//Beneficiary
corporateService.beneficiaries = async (name = '', cardNumber = '', telephone = '', document = '') => {
    const url = "/beneficiaries?"
        + 'filter_name=' + clear(name)
        + '&filter_card=' + clear(cardNumber)
        + '&filter_document=' + clear(document)
        + '&filter_telephone=' + clear(telephone);

    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get(url);
    return data;
};

corporateService.getBeneficiary = async (id) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('beneficiaries/' + id);
    return data;
};

corporateService.beneficiariesPreAprovalls = async (name = '', isEmployee, isPt) => {
    const url = "/beneficiaries/pre-approvals?"
        + 'filter_name=' + clear(name)
        + '&filter_pt=' + isPt
        + '&filter_employee=' + isEmployee;

    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get(url);
    return data;
};

corporateService.updateBeneficiary = async (id, payload) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.put('beneficiaries/' + id, payload);
    return data;
};


corporateService.locations = async (voucherId = '', subjectId = '', zoneId = '') => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('locations?subject_id=' + subjectId + '&voucher_id=' + voucherId + '&zone_id=' + zoneId);
    return data;
};
corporateService.locations2 = async (searchForm) => {
    const { zoneId = '', name = '', hasVoucher = '', network = '' } = searchForm;
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('locations2?filter_name=' + name + '&voucher_id=' + network + '&zone_id=' + zoneId + '&filter_has_voucher=' + hasVoucher);
    return data;
};

corporateService.getProvinces = async () => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('locations/provinces');
    return data;
};


corporateService.getLocationSubjects = async (locationId, voucherId = '') => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('locations/' + locationId + '/subjects/?filter_voucher=' + voucherId);
    return data;
};


corporateService.beneficiaryVouchers = async (id) => {
    const { data } = await jwtAuthAxios.get('beneficiaries/' + id + '/vouchers');
    return data;
};

corporateService.getDocuments = async () => {
    const { data } = await jwtAuthAxios.get('document-types/');
    return data;
};

corporateService.getVoucherZones = async (id) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('zones?voucher_id=' + id);
    return data;
};

corporateService.login = async (email, password) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.post('login', { email: email, password: password });
    return data;
};

corporateService.createTicket = async (payload) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.post('tickets', payload, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return data;
};

corporateService.getTickets = async (subjectId, dateStart, dateEnd) => {

    const url = "/tickets?"
        + '&filter_subject=' + clear(subjectId)
        + '&filter_date_start=' + convertToServertDateFormat(dateStart)
        + '&filter_date_end=' + convertToServertDateFormat(dateEnd)
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get(url);
    return data;
};
corporateService.getTicketById = async (id) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('tickets/' + id);
    return data;
};


corporateService.getTicketsSubjects = async () => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('tickets/subjects');
    return data;
};
corporateService.dashBoardCards = async () => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('dashboard/cards');
    return data;
};

corporateService.createBook = async (payload) => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.post('book', payload, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return data;
}
corporateService.me = async () => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('me');
    return data;
};

corporateService.bookStatus = async () => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('book/status');
    return data;
};
corporateService.bookSubjects = async (name = "") => {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = token;
    const { data } = await jwtAuthAxios.get('book/subjects?name=' + name);
    return data;
};


export default corporateService;