import { Box, Card, Typography } from "@mui/material";
import React from "react";


const CounterCard = (props) => {
    return (
        <Card sx={{ padding: 1 }}>
            <Box display={'flex'} gap={1}>
                <Box sx={{ display: 'flex', 'justifyContent': 'flex-start', alignItems: 'center', gap: 1, p: 2, borderRadius: 2, mb: 1, backgroundColor: props.bgColor }}>
                    {props.icon}
                </Box>
                <Box>
                    {JSON.stringify(props.backgroundColor)}
                    <Typography align="center" fontWeight={400} variant="h5">{props.title}</Typography>
                    <Typography align="center" fontSize={'2rem'} fontWeight={700} variant="h1">{props.value}</Typography>
                </Box>
            </Box>

        </Card>
    )
}

export default CounterCard;