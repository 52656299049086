import React, { useEffect, useState } from "react";
import CollapseParent from "./CollapseComponent/CollapseParent";
import { Grid, Typography } from "@mui/material";

import corporateService from "../../services/corporate";
import ContentLoader from "@jumbo/components/ContentLoader/ContentLoader";
import {
    useQuery,
  } from '@tanstack/react-query';
const ActsPage = () => {

    const { data, isError, isFetching, isLoading, refetch } =
    useQuery(
      ['voucher-network-data'],
      async () => {
        const axiosData = await corporateService.getVouchers()
        return axiosData;
      },
      { keepPreviousData: false,staleTime:60000*5 },
    );
 
    return (
        <>
            <Typography sx={{ color: '#5d5d5d' }} fontWeight={900} variant="h1">Actos Contratados</Typography>
            <Grid container>
                <Grid item xs={12}>
                    {data?.map((item, index) => (
                        <CollapseParent key={index} data={item} />
                    ))}
                </Grid>
            </Grid>
            <ContentLoader open={isLoading}/>
        </>
    )
}

export default ActsPage;