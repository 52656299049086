import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from '../../../hooks/useAuth';

const PublicRoute = ({children}) => {
    console.log("Public Route");

    const {user} = useAuth();

    if (user) {
        return <Navigate to={'/'} replace/>;
    } 

    return children;
};

export default PublicRoute;