import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, DialogContent, Typography, FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, Autocomplete, TextField, Grid, MenuItem, Tooltip, Box, Alert, Chip, Paper, ListItem, ListItemText, List, Divider, ListItemAvatar, Avatar, ListItemIcon } from "@mui/material";
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FolderIcon from '@mui/icons-material/Folder';
import RedeemIcon from '@mui/icons-material/Redeem';
import Dropzone from "react-dropzone";
import { getContrastColor } from "@jumbo/utils";
import corporateService from "app/services/corporate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const SubjectDetailModal = ({ open, handleClose, locationId,voucherId }) => {
    const [data, setData] = useState([]);
        useEffect(() => {
            getLocationSubjects()
        }, []);
        const getLocationSubjects = () => {
            corporateService.getLocationSubjects(locationId,voucherId).then((data) => {
                setData(data);
            })
        }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle>Lista de Especialidades</DialogTitle>
            <DialogContent>
                <Paper style={{ maxHeight: 400, overflow: 'auto' }}>
                    <List >
                        {data?.map((item, index) => (
                            <React.Fragment>
                                <ListItem key={item.id}>
                                    <ListItemText
                                        primary={item.name}
                                    />
                                    {item.hasVoucher ?
                                        <Tooltip title="Acto Disponivel">
                                            <ListItemIcon>
                                                <RedeemIcon color="primary" />
                                            </ListItemIcon>
                                        </Tooltip>
                                        : null
                                    }
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
            </DialogContent>
        </Dialog >
    )

}

export default SubjectDetailModal;