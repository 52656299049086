import React, { useEffect, useState } from "react";
import Slide from '@mui/material/Slide';
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, DialogContent, Typography, FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, Autocomplete, TextField, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import corporateService from "app/services/corporate";
import DatePickerField from "@jumbo/components/DatePickerField";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    price: yup
        .string()
        .required(),
    size: yup.string().required()
});

const BeneficiaryDetailModal = ({ open, handleClose, beneficiary }) => {
    const [vouchers, setVouchers] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [zones, setZones] = useState([]);
    const [subjectValue, setSubjectValue] = React.useState(null);
    const [locationValue, setLocatioValue] = React.useState(null);
    const [locations, setLocations] = useState([]);

    const [initialValues, setInitialValues] = useState({
        voucher_id: '',
        subject_id: '',
        location_id: '',
        zone_id: '',
        appointment_date: '',
        notes: ''

    })
    useEffect(() => {
        getAvailableVoucher()
    }, []);


    const getAvailableVoucher = () => {
        corporateService.beneficiaryVouchers(beneficiary.customer_subscription_has_entity).then((data) => {
            if (data.length == 1) {
                setInitialValues({ ...initialValues, voucher_id: data[0].id })
                setVouchers(data);
                getZones(data[0].id);


            }
        })
    }

    const getVoucherSubjects = (voucherId, zoneId) => {
        corporateService.getVouchersSubjects(voucherId, zoneId).then((data) => {
            setSubjects(data);
        })
    }

    const getZones = (voucherId) => {
        corporateService.getVoucherZones(voucherId).then((data) => {
            setZones(data);
        })
    }

    const getLocations = (voucherId, subjectId, zoneId) => {
        corporateService.locations(voucherId, subjectId, zoneId).then((data) => {
            setLocations(data);
        })
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth={'md'}
        >
            <Formik
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(data, { setSubmitting }) => {

                }}
            >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                        <DialogTitle>Editar Beneficiário - {beneficiary.name}</DialogTitle>
                        <DialogContent>
                         <Grid>
                            dddd
                         </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancelar</Button>
                            <Button onClick={handleClose}>Gravar</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )

}

export default BeneficiaryDetailModal;