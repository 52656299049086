import { Box, Button, Card, CardContent, CardHeader, Chip, Typography } from "@mui/material";
const NextAppointmentsCard = ({ data }) => {

    const ScheduledDetail = ({ item }) => {
        return (
            <Box sx={{ display: 'flex', gap: '16px', mb: 1 }}>
                <Box sx={{ display: 'flex', color: 'white', minWidth: '90px', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: 4, borderBottomRightRadius: 4, borderBottomLeftRadius: 4, borderTopLeftRadius: 4, padding: '4px' }} bgcolor={'#0f172a'}>
                    <Typography sx={{ margin: 0, color: '#FFF' }} variant="h4" fontWeight={900}>{item.month_day}</Typography>
                    <Typography sx={{ margin: 0, color: '#FFF' }} variant="h4" fontWeight={400}>{item.month_name}</Typography>
                </Box>
                <Box>
                    <Typography sx={{ margin: 0 }} variant="h6">{item.name}</Typography>
                    <Typography sx={{ margin: 0 }} variant="body1">Luanda Medical Center</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap:1
                    }}>
                        <Typography sx={{ margin: 0 }} fontWeight={700} variant="body2">{item.subject_name}</Typography>
                        <Chip size="small" sx={{ fontSize: '0.7rem' }} label="Desconto Aplicado" />
                    </Box>
                </Box>
            </Box>
        )
    }
    return (
        <Box >
            <Card>
                <CardHeader title={'Proximas Consultas'} />
                <CardContent>
                    {data.map((item, index) => (
                        <ScheduledDetail key={item.id} item={item} />
                    ))}
                    {data.length == 0 ? <Typography>Sem Agendamentos.</Typography> : null}
                </CardContent>

            </Card>
        </Box>

    )

}

export default NextAppointmentsCard;